var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.item.Value),function(content,index){return [(content != '')?_c('div',{key:index},[_c('h3',[_c('v-icon',[_vm._v("mdi-file-document")]),_vm._v(" "+_vm._s(content)+" "),_c('v-btn',{attrs:{"depressed":"","color":"primaryred","icon":""},on:{"click":function($event){return _vm.$delete(_vm.item.Value, index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]):_vm._e()]}),_c('v-layout',{attrs:{"align-center":""}},[(_vm.item.MultipleItems)?_c('v-autocomplete',{attrs:{"height":"28","dense":"","items":_vm.searchResults,"placeholder":_vm.item.LoadItemsWhenCreated ? '' : _vm.$t('Search') + '...',"small-chips":"","loading":_vm.searchingContent,"search-input":_vm.searchContentWatch,"multiple":_vm.item.MultipleItems,"chips":_vm.item.MultipleItems,"deletable-chips":_vm.item.MultipleItems,"item-text":_vm.showItem,"item-value":"_id","rules":_vm.dropdownRule,"clearable":!_vm.item.Required,"hide-no-data":"","disabled":_vm.$helpers.itemNeedsDisable(_vm.item.ReadOnly, _vm.needsDisable, _vm.editItemAdmin),"outlined":"","hide-details":"auto"},on:{"update:searchInput":function($event){_vm.searchContentWatch=$event},"update:search-input":function($event){_vm.searchContentWatch=$event}},scopedSlots:_vm._u([{key:"selection",fn:function({ attr, on, item, selected }){return [_c('v-chip',_vm._g(_vm._b({attrs:{"input-value":selected,"small":"","close":item.ReadOnly ||
            _vm.editItemAdmin ||
            (_vm.$store.getters.hasUserRoleForPageItem({
              role: 2,
            }) &&
              !_vm.userIsAdministrator)},on:{"click:close":function($event){return _vm.remove(item)}}},'v-chip',attr,false),on),[(item.DisplayLabels)?_c('span',{domProps:{"textContent":_vm._s(item.DisplayLabels[_vm.currentLanguage])}}):_c('span',{domProps:{"textContent":_vm._s(item.DisplayLabel)}}),(_vm.checkGoToLinkedContent)?_c('v-btn',{staticClass:"ml-1",attrs:{"depressed":"","right":"","x-small":"","icon":""},on:{"click":function($event){return _vm.$helpers.goToLinkedContentPage(
                item,
                item.PageId.$oid,
                item._id.$oid
              )}}},[_c('v-icon',[_vm._v(" mdi-arrow-right ")])],1):_vm._e()],1)]}}],null,false,2654851031),model:{value:(_vm.item.LinkedContent),callback:function ($$v) {_vm.$set(_vm.item, "LinkedContent", $$v)},expression:"item.LinkedContent"}}):_c('v-autocomplete',{ref:"autocomplete",attrs:{"items":_vm.searchResultsWithUpdatedItemText,"height":"28","placeholder":_vm.item.LoadItemsWhenCreated ? '' : _vm.$t('Search') + '...',"loading":_vm.searchingContent,"search-input":_vm.searchContentWatch,"item-text":_vm.showItemAutocomplete,"item-value":"_id","rules":_vm.dropdownRule,"clearable":!_vm.item.Required,"hide-no-data":"","disabled":_vm.$helpers.itemNeedsDisable(_vm.item.ReadOnly, _vm.needsDisable, _vm.editItemAdmin),"hide-details":"auto","dense":"","outlined":""},on:{"update:searchInput":function($event){_vm.searchContentWatch=$event},"update:search-input":function($event){_vm.searchContentWatch=$event},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(item.DisplayLabels ? item.DisplayLabels[_vm.currentLanguage] : item.DisplayLabel)+" ")]}}]),model:{value:(_vm.item.LinkedContent),callback:function ($$v) {_vm.$set(_vm.item, "LinkedContent", $$v)},expression:"item.LinkedContent"}}),(
        (_vm.userIsAdministrator && _vm.item.LinkedContent && !_vm.item.MultipleItems) ||
        (_vm.$helpers.userHasAccessToLinkedContent(_vm.item, _vm.selectedWebsite.Pages) &&
          _vm.item.LinkedContent &&
          !_vm.item.MultipleItems)
      )?_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","disabled":!_vm.item.LinkedContent,"color":"primary","icon":""},on:{"click":function($event){return _vm.$helpers.goToLinkedContentPage(_vm.item)}}},[_c('v-icon',[_vm._v(" mdi-arrow-right")])],1):_vm._e()],1),(_vm.$store.getters.editMode)?[_c('hr',{staticClass:"mt-4"}),_c('v-row',{staticClass:"my-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{staticClass:"mr-4 altSelect",attrs:{"items":_vm.getSharedPagesNotAssigned(),"single-line":"","item-value":"_id","label":_vm.$t('Select a page'),"outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('div',{staticClass:"input-group__selections__comma"},[_vm._v(" "+_vm._s(data.item.Name[_vm.currentLanguage])+" ")])]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.Name[_vm.currentLanguage])+" ")]}}],null,false,656538476),model:{value:(_vm.item.LinkedPage),callback:function ($$v) {_vm.$set(_vm.item, "LinkedPage", $$v)},expression:"item.LinkedPage"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":_vm.item.colWidth < 7
                ? _vm.item.colWidth < 5
                  ? 12
                  : 4
                : _vm.item.colWidth < 10
                ? 4
                : 2}},[_c('v-layout',{attrs:{"column":""}},[_c('v-text-field',{attrs:{"hide-details":"auto","type":"number","max":"12","min":"1","label":_vm.$t('Column width'),"outlined":"","dense":""},model:{value:(_vm.item.colWidth),callback:function ($$v) {_vm.$set(_vm.item, "colWidth", _vm._n($$v))},expression:"item.colWidth"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"label":_vm.$t('Load items when created'),"hide-details":"auto"},model:{value:(_vm.item.LoadItemsWhenCreated),callback:function ($$v) {_vm.$set(_vm.item, "LoadItemsWhenCreated", $$v)},expression:"item.LoadItemsWhenCreated"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Multiple items')},model:{value:(_vm.item.MultipleItems),callback:function ($$v) {_vm.$set(_vm.item, "MultipleItems", $$v)},expression:"item.MultipleItems"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Field is required')},model:{value:(_vm.item.Required),callback:function ($$v) {_vm.$set(_vm.item, "Required", $$v)},expression:"item.Required"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Read-only')},model:{value:(_vm.item.ReadOnly),callback:function ($$v) {_vm.$set(_vm.item, "ReadOnly", $$v)},expression:"item.ReadOnly"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Remove frame')},model:{value:(_vm.item.RemoveCard),callback:function ($$v) {_vm.$set(_vm.item, "RemoveCard", $$v)},expression:"item.RemoveCard"}})],1)],1)],1)],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }